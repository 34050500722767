import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import qs from 'qs'
import { getConfig } from '@/common/config'

// implementation
const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

axiosInstance.interceptors.request.use((config) => {
  config.baseURL = getConfig().oauth2.endpoint
  return config
})

const handleTokenResponse = (response) => {
  console.log('got token', response.data)

  let accessToken = response.data.access_token
  console.log('access token', accessToken)

  let refreshToken = response.data.refresh_token
  console.log('refresh token', refreshToken)

  let token = jwtDecode(accessToken)
  console.log('decoded token', token)
  let tokenType = response.data.token_type
  console.log('token type', tokenType)
  let expireTime = token.exp
  console.log('expire time', expireTime)

  return {
    accessToken,
    refreshToken,
    token,
    tokenType,
    expireTime
  }
}

export const useOAuth2Store = defineStore({
  id: 'oauth2Store',
  persist: {
    key: import.meta.env.VITE_LOCAL_STORAGE_PREFIX + 'oauth2Store',
    storage: localStorage
  },
  state: () => ({
    tokenInfo: null,
    refreshTokenInProgress: false
  }),
  getters: {
    // private
    getterTokenInfo(state) {
      // if exists in localStorage
      return state.tokenInfo
    },
    getterRefreshToken(state) {
      if (!state.tokenInfo) {
        return null
      }

      return state.tokenInfo.refreshToken
    },
    getterCurrentUser(state) {
      if (!state.tokenInfo) {
        return null
      }

      return state.tokenInfo.token.preferred_username
    },
    getterCurrentUserFullName(state) {
      if (!state.tokenInfo) {
        return null
      }

      return state.tokenInfo.token.given_name + ' ' + state.tokenInfo.token.family_name
    },
    getterCurrentUserUId(state) {
      if (!state.tokenInfo) {
        return null
      }

      return state.tokenInfo.token.sub
    }
  },
  actions: {
    signin(payload) {
      var username = payload.userName
      var password = payload.password
      var data = {
        //...authAccessTokenBaseData,
        grant_type: getConfig().oauth2.grantType,
        client_id: getConfig().oauth2.clientId,
        scope: getConfig().oauth2.scope,
        username,
        password
      }

      console.log('start requesting token', data)

      return axiosInstance
        .post('', qs.stringify(data))
        .then((response) => {
          console.log('full login response', response)

          var tokenInfo = handleTokenResponse(response)

          this.tokenInfo = tokenInfo

          return tokenInfo
        })
        .catch((error) => {
          console.log('getting token error', error)

          return Promise.reject(error)
        })
    },
    signout() {
      console.log('in signout')
      this.tokenInfo = null
      this.refreshTokenInProgress = false

      return Promise.resolve()
    },
    doRefreshToken() {
      if (this.refreshTokenInProgress) {
        console.log('Another refresh token request is in progress, will return the current tokenInfo.')
        return Promise.resolve(this.tokenInfo) 
      }

      if (!this.tokenInfo || !this.tokenInfo.refreshToken) {
        throw new Error('Refresh token is not available.')
      }

      this.refreshTokenInProgress = true

      // eslint-disable-next-line camelcase
      var refresh_token = this.tokenInfo.refreshToken
      var data = {
        //...authRefreshTokenBaseData,
        grant_type: 'refresh_token', // this won't be changed
        client_id: getConfig().oauth2.clientId,
        refresh_token
      }

      console.log('start requesting refresh token', data)

      return axiosInstance
        .post('', qs.stringify(data))
        .then((response) => {
          var tokenInfo = handleTokenResponse(response)

          this.refreshTokenInProgress = false
          this.tokenInfo = tokenInfo

          return tokenInfo
        })
        .catch((error) => {
          console.error('getting refresh token error', error)

          this.refreshTokenInProgress = false
          this.tokenInfo = null
          this.refreshTokenInProgress = false

          return Promise.reject(error)
        })
    }
  }
})
