import zhLocale from 'element-plus/dist/locale/zh-cn.mjs'

let zhCn = {
  el: zhLocale.el,
  common: {
    current_locale: '中文',
    current_user: '当前用户',
    chinese: '中文',
    english: 'English',
    search: '查询',
    cancel: '取消',
    submit: '提交',
    confirm: '确认',
    add: '新增',
    create: '创建',
    edit: '编辑',
    update: '更新',
    delete: '删除',
    batch_delete: '批量删除',
    remove: '移除',
    import: '导入',
    export: '导出',
    action: '操作',
    general_confirm: '确认吗？',
    delete_confirm: '确认删除？',
    batch_delete_confirm: '确认批量删除？',
    prompt: '提示',
    no_record: '未选择任何记录。',
    create_success: '创建成功！',
    update_success: '更新成功！',
    delete_success: '删除成功！',
    batch_delete_success: '批量删除成功！',
    u_id: '编号',
    name: '名称',
    display_name: '显示名称',
    type: '类别',
    enabled: '是否启用',
    description: '描述',
    create_by: '创建人',
    create_time: '创建时间',
    update_by: '修改人',
    update_time: '修改时间',
    mandatory: '必填',
    format_error: '格式错误',
    import_prompt: '将文件拖到此处，或点击上传',
    import_tip: '只能上传xlsx文件',
    please_select: '请选择',
    all: '所有',
    save: '保存',
    yes: '是',
    no: '否',
    start_date: '开始日期',
    end_date: '结束日期',
    to: '至',
    last_three_weeks: '过去3周',
    last_month: '过去1个月',
    last_three_months: '过去3个月',
    last_week: '过去1周',
    detail: '详情',
    ok: '确认',
    system_info: '系统信息',
    os_platform: '操作系统',
    runtime_version: '运行时版本',
    memory_usage: '内存使用',
    app_version: '系统版本',
    app_build_time: '构建时间',
    app_git_commit: '提交信息',
    select_date: '选择日期',
    key: '键',
    value: '值',
    reset: '重置',
    common_filter: '通用过滤',
    true: '是',
    false: '否',
    copied: '已复制！',
    copy_failed: '复制失败！',
    system_name: 'Solution Template',
    error: '错误',
    signout_try_again: '退出重试',
    back: '返回',
    upload: '上传',
    upload_failed: '上传失败',
    preview: '预览'
  },
  router: {
    root: '根目录',
    home: '首页',
    notification: '系统消息',
    system: '系统',
    system_log_main: '系统日志',
    system_config_main: '系统配置',
    document_main: '文件',
    loading: '正在加载...',
    error: '错误',
    access_denied: '拒绝访问',
    not_found: '未找到相关资源.',
    event_main: '事件',
    event: '事件',
    no_permission: '没有权限',
    attachment: '附件'
  },
  signin: {
    signin: '登录',
    please_signin: '请登录',
    user_name: '用户名',
    password: '密码',
    remember_password: '记住密码',
    user_name_missing: '请输入用户名。',
    password_missing: '请输入密码。',
    terms_and_conditions: '条款和条件',
    privacy_statement: '隐私声明',
    signin_failed: '登录失败',
    sign_in_failed_prompt: '登录失败, 原因: {reason}'
  },
  error: {
    error: '错误',
    back_to_home: '返回',
    service_call_fail: '调用服务失败，详情：',
    no_permission_prompt: '您没有任何系统访问权限，请联系管理员。'
  },
  json_ql: {
    all_records: '选择视图',
    create_view: '创建视图',
    update_view: '更新视图',
    clone_view: '复制视图',
    delete_view: '删除视图',
    filters: '筛选条件',
    cancel: '取消',
    save_filter: '保存筛选',
    add_filter: '增加筛选',
    mandatory: '必填',
    view_detail: '视图详情',
    name: '名称',
    match_all_filters: '筛选条件之间使用与关系',
    match_custom_logic: '筛选条件之间自定义逻辑',
    remove_all_filter_prompt: '确定删除所有筛选？',
    confirm: '确认',
    remove_all: '删除所有',
    filter_logic: '筛选条件逻辑',
    add_filter_logic: '增加筛选逻辑',
    remove_filter_logic: '删除筛选逻辑',
    delete_confirm: '确认删除?',
    delete_prompt: '确认',
    field: '字段',
    operator: '操作符',
    value: '值',
    eq: '等于',
    neq: '不等于',
    exist: '存在',
    prefix: '开始为',
    suffix: '结束为',
    like: '包含',
    nlike: '不包含',
    in: '在列表内',
    nin: '不列表在内',
    gt: '大于',
    lt: '小于',
    gte: '大于等于',
    lte: '小于等于',
    done: '完成',
    select_date_time: '选择日期',
    search_by_query: '使用查询语句搜索'
  },
  home: {
    my_message: '我的消息',
    profile: '个人设置',
    settings: '设置',
    logout: '退出',
    logout_prompt: '确认退出吗？',
    logout_caption: '退出确认',
    clear_cache: '清空缓存',
    cache_cleared_success: '缓存已清空。',
    fullscreen: '全屏',
    exit_fullscreen: '退出全屏',
    fullscreen_not_supported: '您的浏览器不支持全屏模式。',
    theme: '主题',
    theme_default: '默认',
    theme_dark: '深色'
  },
  system_log: {
    operation_time: '操作时间',
    operation_object: '操作对象',
    operation_object_id: '操作对象编号',
    operation: '操作',
    operator_ip: '操作人IP',
    operator_name: '操作人姓名',
    category: '分类',
    source: '源',
    type: '类别',
    id: '编号',
    data: '数据'
  },
  system_config: {
    key: '键',
    value: '值'
  },
  event: {
    generate_events: 'Generate Events'
  },
  notification: {
    notification: '系统消息',
    view_all: '查看所有消息',
    user_u_id: '用户编号',
    is_read: '是否已读',
    is_delete: '是否删除',
    type: '类别',
    status: '状态',
    title: '标题',
    message: '消息内容',
    priority: '优先级',
    expiration_time: '过期时间',
    url: '链接',
    icon: '图标',
    additional_data: '更多信息',
    mark_all_read: '全部标记为已读',
    mark_unread: '标记为未读',
    mark_read: '标记为已读'
  },
  attachment: {
    files_to_upload: '文件上传',
    type: '类型',
    associated_object_u_id: '关联对象编号',
    file_name: '文件名',
    physical_file_name: '物理文件名',
    media_type: '媒体类型',
    sort_index: '排序索引'
  },
  message: '你好',
  ...zhLocale // Or use `Object.assign({ message: 'hello' }, enLocale)`
}

export default zhCn
