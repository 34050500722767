import { createI18n } from 'vue-i18n'

import en from './en'
import zhCn from './zh-cn'

let language = {
  ['en']: en,
  ['zh-cn']: zhCn
}

console.log('language:', language)

let availableLangs = Object.keys(language)

let defaultLanguage = 'en'
if (window.localStorage.language && availableLangs.indexOf(window.localStorage.language) >= 0) {
  defaultLanguage = window.localStorage.language
} else if (navigator.language && availableLangs.indexOf(navigator.language) >= 0) {
  defaultLanguage = navigator.language
}

const i18n = createI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'en',
  messages: language, // set locale messages,
  missing: (locale, key) => {
    // handle translation missing
    var module = key.slice(0, key.indexOf('.'))
    var transKey = key.split('.').pop()

    var tokens = transKey.split('_')
    var transValue = ''
    for (let t of tokens) {
      transValue += t.charAt(0).toUpperCase() + t.slice(1) + ' '
    }

    let missingKeysStr = localStorage.getItem('missingKeys')

    let missingKeys = {}
    try {
      missingKeys = JSON.parse(missingKeysStr)
      if (!missingKeys) missingKeys = {}
    } catch {
      missingKeys = {}
    }

    if (!missingKeys[module]) missingKeys[module] = {}

    missingKeys[module][transKey] = transValue.trim()

    localStorage.setItem('missingKeys', JSON.stringify(missingKeys))

    console.log('!!!missing key!!! ' + module)
    console.log(transKey + ': ' + "'" + transValue.trim() + "',")
  }
})

let setLocale = (locale) => {
  console.log('setLocale', i18n, locale)

  if (locale === undefined) {
    i18n.global.locale = defaultLanguage
  } else {
    i18n.global.locale = locale
  }
  window.localStorage.language = i18n.global.locale
}

let getLocale = () => {
  return i18n.global.locale
}

export default { i18n, setLocale, getLocale }
